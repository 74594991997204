.participant {
  background-color: #353b48;
  max-width: 250px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 2vh;
  .video {
    background-color: #353b48;
    height: 100%;
    width: 100%;
    position: relative;
    object-fit: cover;
    border-radius: 5px;
  }

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    font-size: 58px;
    font-family: roboto, arial;
    color: white;
    text-align: center;
    background: orange;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

  .muted {
    color: #fff;
    right: 10px;
    position: absolute;
    top: 10px;
    border-radius: 50%;
    height: 22px;
    width: 30px !important;
  }

  .name {
    color: white;
    top: 10px;
    position: absolute;
    left: 20px;
    background-color: #15171b96;
    padding: 2%;
    border-radius: 5px;
  }

  .animator {
    position: absolute;
    right: -25px;
    display: flex;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    color: #f7931e;
  }
}

.admin {
  border: 3px solid #f7931e;
}

@media (max-width: 800px) {
  .participant {
    min-height: 0px;
    max-width: 150px;
    .avatar {
      width: 80px;
      height: 80px;
      font-size: 50px;
    }
  }
}
