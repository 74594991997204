.ObjectContent--isDisplay {
  position: absolute;
}

.ObjectContent-day:hover {
  cursor: url('../../../../../../img/pink.cur'), pointer;
}
.ObjectContent-night:hover {
  cursor: url('../../../../../images/image-2.png') 50 50, default;
}

.ObjectContent--isNotDisplay,
.ObjectContent--isDisplayCode {
  position: absolute;
  opacity: 0;
  transition: 1000ms;
}
.ObjectContent--isNotDisplay {
  display: none;
}

.ObjectContent--isDisplayCode:hover {
  position: absolute;
  opacity: 0.8;
}

.bounceRobot {
  animation: bounceRobot 5s ease infinite;
}

.Vibrating {
  animation: Vibrating 1s linear infinite;
}

@keyframes bounceRobot {
  from {
    transform: rotateZ(0deg) translateY(15px);
  }
  35% {
    transform: rotateZ(-1deg);
  }
  45% {
    transform: rotateZ(1deg);
  }
  55% {
    transform: rotateZ(-1deg) translateY(-15px);
  }
  70% {
    transform: rotateZ(1deg);
  }
  to {
    transform: rotateZ(0deg) translateY(15px);
  }
}

@keyframes Vibrating {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  5% {
    transform: translate(-1px, -1px) rotate(-1deg);
  }
  10% {
    transform: translate(-1px, 0px) rotate(1deg);
  }
  15% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  20% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  25% {
    transform: translate(-1px, 1px) rotate(-1deg);
  }
  30% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  35% {
    transform: translate(1px, 1px) rotate(-1deg);
  }
  40% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  45% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  50% {
    transform: translate(1px, -1px) rotate(-1deg);
  }
  55% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
