.checkroom {
  text-align: center;
  display: flex;
  align-items: center;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Fbackgrounds%2Fintro-background.svg?alt=media&token=1b191a05-95f0-44d3-b87c-33930f10a54b);
  background-size: cover;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  padding: 5%;

  .footer {
    position: absolute;
    bottom: 2%;
    margin: 0 5%;

    p {
      color: white;
      text-align: center;
      font-weight: 300;
      font-size: 32px;
      line-height: 39px;
    }
  }
}

.checkroom-game {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;

  .footer {
    position: absolute;
    bottom: 2%;
    margin: 0 5%;

    p {
      color: white;
      text-align: center;
      font-weight: 300;
      font-size: 32px;
      line-height: 39px;
    }
  }
}
