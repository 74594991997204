.book-404 img {
  position: absolute;
  z-index: 2;
  height: 9%;
  top: 58.5%;
  left: 16%;
  transform: rotate3d(1, 1, 1, -35deg);
}

.TextPaper img {
  position: absolute;
  height: 16%;
  top: 40.5%;
  left: 41.3%;
  animation: appearsSymbol 1.9s ease infinite;
}

@keyframes appearsSymbol {
  from {
    opacity: 0.2;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.7;
  }
  55% {
    opacity: 1;
  }
  75% {
    opacity: 0.2;
  }
  to {
    opacity: 0;
  }
}
