.debriefing {
  color: white;
  background-color: rgba(0, 0, 0, 0.2901960784);
  border-radius: 10px;
  padding: 5px 30px;
  font-size: xx-large;
  width: 100%;
  .question-image {
    margin: 6% 4% 4% 4%;
    height: 250px;
    border-radius: 5%;
  }
  .participants {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    .participant {
      color: white;
      background-color: rgb(162 152 152 / 29%);
      border-radius: 10px;
      padding: 5px 30px;
      font-size: xx-large;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      width: 40%;
      margin: 2%;
    }
  }
  .time {
    font-size: 60px;
  }
}
