.remoteControl-image {
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.remoteControl-image-Remote {
  width: 20%;
}

.remoteControl-image-court img {
  position: absolute;
  width: 12%;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}
.remoteControl-image-long img {
  position: absolute;
  width: 12%;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}
.remoteControl-image-play img {
  position: absolute;
  width: 12%;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.remoteControl {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.remoteControl.Modal_description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  height: 100%;
}

.remoteControl.Modal_description-text {
  font-size: 1.4vw;
  width: 70%;
}

.remoteControl-chaining {
  display: flex;
  justify-content: center;
}
.remoteControl-chaining img {
  height: 1.2vw;
  margin: 0px 5px;
}
