body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* OVERALL */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');

:root {
  --red: rgb(255, 135, 107);
  --green: rgb(43, 157, 143);
}

html,
body,
#root {
  height: 100vh;
  background-color: #2d1837;
  overflow: hidden;
}

html {
  background-color: #2d1837;
  cursor: url('./img/pink.cur'), default;
  cursor: url('./img/pink.cur'), pointer;
  /* Block the user selection with the mouse on the page that was causing an highlight (blue in Chrome).
   We generally don't want the highlight. */
  user-select: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Montserrat', 'Comfortaa', Arial, Helvetica, sans-serif;
  background-color: #f7f8fc !important;
  --bg-nav: #363740;
}

*,
::after,
::before {
  box-sizing: border-box;
}

h3 {
  font-size: 1rem !important;
}

p:hover {
  cursor: url('./img/pink.cur'), default;
  cursor: url('./img/pink.cur'), pointer;
}

h1 {
  font-size: 2.5rem;
}

h1,
h2,
h3 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

h1,
h2,
h3,
p,
input {
  font-family: 'Montserrat', 'Comfortaa', Arial, Helvetica, sans-serif;
  /* color: #fff;
  margin: 0px;
  font-weight: 300; */
}

strong {
  font-weight: bolder;
}

button:active,
button:focus,
button:hover {
  outline: none;
  text-decoration: none;
  /* This cursor is already defined for buttons in line 232 */
  /* cursor: url('./img/pink.cur'), pointer; */
}

:focus {
  outline: none;
}

a:active,
a:focus,
a:hover {
  outline: 0;
  border: none;
  text-decoration: none;
}

a:-webkit-any-link {
  text-decoration: none;
}

/* GAME */
.Game {
  display: flex;
  background: #2d1837;
}

.Game-Waiting {
  display: flex;
  background: #2d1837;
  flex-direction: column;
  height: 100vh;
}

/* LOGIN */

.Form-container {
  width: 600px;
  /* border: 1px solid #ced4da; */
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px;
  margin: auto;
  text-align: center;
}

.Form-container h2 {
  font-size: 1.5rem !important;
  font-weight: 700;
  color: #000;
}

.Form-p {
  font-size: 1.6rem;
  font-weight: 300;
  margin-bottom: 10px;
  color: #000;
  text-align: center;
}

.Form-p-Check {
  font-weight: 700 !important;
  font-size: 0.9vw !important;
  /* text-align: center; */
}

.Form-h1-red {
  color: #7a00ff;
  text-align: center;
}

.Form-h1 {
  text-align: center;
  font-size: 1.8vw;
}

.Mug-Enter {
  width: 200px;
  min-height: 100px;
}

.CheckPage-Exit-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Marm-Enter {
  width: 100%;
  margin-left: 10px;
}

.Bottle-Enter {
  width: 50%;
  margin-left: 30%;
}

.Login-Welcome {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login-Mug {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.Login-Mug h2 {
  margin-bottom: 60px;
}

.Login-Footer {
  height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Form-h2 {
  font-weight: 600;
  font-size: 1.3vw;
  /* margin-bottom: 1.5rem;
  margin-top: 0px; */
}

.Form-h2-Check {
  font-weight: 700;
  font-size: 1.5vw;
  text-align: center;
  width: 50%;
  /* margin-bottom: 1.5rem;
  margin-top: 0px; */
}

.ml-0 {
  margin-left: 0 !important;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: url('./img/pink.cur'), pointer;
}

.LoginForm {
  background: #fff;
  border-radius: 10px;
  margin: auto;
  padding: 30px;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
}

.LoginLabels {
  color: #9fa2b4;
  text-align: center;
  font-size: 0.9vw;
  /* margin-top: 1.5rem; */
}

.LoginLabels a {
  color: #9fa2b4;
  font-size: 0.9vw;
  cursor: url('./img/pink.cur'), pointer;
}

.Admin-Login {
  color: #000;
  text-align: center;
}

/* HEADER */

.Header-bkg {
  background: #2d1837;
}

.Header-logo {
  width: 10%;
}

.Header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  width: 100%;
  height: 100%;
}

.HeaderWaitingRoom-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.Header-settings {
  margin-right: 50px;
}

.fa-cog {
  color: #fff;
}

/* BUTTONS */

.Survey-Button-Container {
  display: flex;
  justify-content: space-between;
}

.ButtonAction {
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  margin: 15px;
  z-index: 10;
}

.ButtonActionDoc {
  text-align: center;
  border: rgb(112, 107, 107);
  color: rgb(112, 107, 107);
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  margin-top: 75vh;
  margin-left: 46.8vw;
  z-index: 10;
}

.ButtonActionEmotion {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  z-index: 10;
  position: absolute;
  width: 200px;
  top: 5%;
  right: 5%;
}
.ButtonActionEmotion2 {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  z-index: 10;
  position: absolute;
  width: 200px;
  top: 15%;
  right: 5%;
}
.ButtonActionEmotionPlayer {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  z-index: 101;
  position: absolute;
  width: 200px;
  top: 5%;
  right: 5%;
}

.ButtonStart {
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  margin: 15px;
  z-index: 60;
}

.ButtonGroupName {
  text-align: center;
  color: #fff;
  padding: 3px 10px;
  border: none;
  border-radius: 5px;
  margin: 15px;
  z-index: 10;
}

.ButtonActionDashboard {
  text-align: center;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
}

.NotReady {
  background: none;
  border: 1px solid #fff;
  cursor: url('./img/pink.cur'), pointer;
}

.NotReady:hover {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #fff;
  cursor: url('./img/pink.cur'), pointer;
}

.VaccAction {
  background-color: #4ed199;
  border: 1px solid #48c48e;
  cursor: url('./img/pink.cur'), pointer;
  font-weight: 700;
  font-size: 1.1vw;
}
.VaccAction:hover {
  background-color: #48c48e;
  border: 1px solid #48c48e;
  cursor: url('./img/pink.cur'), pointer;
  font-weight: 700;
}

.VaccActionInstall {
  background: linear-gradient(81.96deg, #da2d64 0%, #e4638c 100%);
  border: 1px solid #e4638c;
  cursor: url('./img/pink.cur'), pointer;
  font-weight: 700;
  font-size: 1.1vw;
}
.VaccActionInstall:hover {
  background: linear-gradient(81.96deg, #da2d64 100%, #e4638c 0%);
  border: 1px solid #da2d64;
  cursor: url('./img/pink.cur'), pointer;
  font-weight: 700;
}

.VaccActionBlur {
  background: transparent;
  background-color: #4ed199;
  border: 1px solid #48c48e;
  cursor: url('./img/pink.cur'), pointer;
  filter: blur(3px);
  font-weight: 700;
}

.Action {
  background-image: linear-gradient(180deg, #7a00ff, #5a00bb);
  border: 1px solid #270051;
  cursor: url('./img/pink.cur'), pointer;
}
.Action:hover {
  background-image: linear-gradient(180deg, #460090, #370072);
  border: 1px solid #270051;
  cursor: url('./img/pink.cur'), pointer;
}

.Show {
  display: block;
  width: 100%;
}

.Back {
  background-color: white;
  color: #000;
  border: 1px solid #9fa2b4;
}

.Back:hover {
  background-color: #7a00ff;
  border: 1px solid #7a00ff;
  color: #fff;
  cursor: url('./img/pink.cur'), pointer;
}

.ButtonAction:disabled {
  color: #000;
  background-color: #9fa2b4;
  border: 1px solid #9fa2b4;
  opacity: 0.2;
  cursor: url('./img/pink.cur'), pointer;
}

.ButtonStart:disabled {
  /* color: #000;
  background-color: #9fa2b4;
  border: 1px solid #9fa2b4; */
  opacity: 0.2;
}

.CloseWindow img {
  position: absolute;
  width: 5%;
  top: 5%;
  right: 5%;
}

.ButtonAction-Word {
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  margin: 15px;
}

.Buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Action-Word {
  background-color: #7a00ff;
  cursor: url('./img/pink.cur'), pointer;
}
.Action-Word:hover {
  background-color: #460090;
  cursor: url('./img/pink.cur'), pointer;
}

.chevron-next {
  width: 15px;
}

.chevron-start {
  width: 12px;
  margin-left: 10px;
}

.check {
  width: 25px !important;
}

.Cancel {
  background-color: #000;
  cursor: url('./img/pink.cur'), pointer;
}
.Cancel:hover {
  background-color: darkslategray;
  cursor: url('./img/pink.cur'), pointer;
}

.Hide {
  display: none;
}

.Form-Input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.container {
  width: 100%;
}

.Login-Container {
  height: 80vh;
  background-color: #2d1837;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.Loader-Container {
  height: 80vh;
  background-color: #2d1837;
}

.ErrorLogin {
  background-color: #2d1837;
}

.holder {
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #2d1837;
}
.preloader {
  /* size */
  width: 10vw;
  height: 10vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: rotatePreloader 2s infinite ease-in;
}
@keyframes rotatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
  }
}
.preloader div {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.preloader div:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0%;
  width: 10%;
  height: 10%;
  background-color: #ffffff;
  transform: translateX(-50%);
  border-radius: 50%;
}
.preloader div:nth-child(1) {
  transform: rotateZ(0deg);
  animation: rotateCircle1 2s infinite linear;
  z-index: 9;
}
@keyframes rotateCircle1 {
  0% {
    opacity: 0;
  }
  0% {
    opacity: 1;
    transform: rotateZ(36deg);
  }
  7% {
    transform: rotateZ(0deg);
  }
  57% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(2) {
  transform: rotateZ(36deg);
  animation: rotateCircle2 2s infinite linear;
  z-index: 8;
}
@keyframes rotateCircle2 {
  5% {
    opacity: 0;
  }
  5.0001% {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  12% {
    transform: rotateZ(-36deg);
  }
  62% {
    transform: rotateZ(-36deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(3) {
  transform: rotateZ(72deg);
  animation: rotateCircle3 2s infinite linear;
  z-index: 7;
}
@keyframes rotateCircle3 {
  10% {
    opacity: 0;
  }
  10.0002% {
    opacity: 1;
    transform: rotateZ(-36deg);
  }
  17% {
    transform: rotateZ(-72deg);
  }
  67% {
    transform: rotateZ(-72deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(4) {
  transform: rotateZ(108deg);
  animation: rotateCircle4 2s infinite linear;
  z-index: 6;
}
@keyframes rotateCircle4 {
  15% {
    opacity: 0;
  }
  15.0003% {
    opacity: 1;
    transform: rotateZ(-72deg);
  }
  22% {
    transform: rotateZ(-108deg);
  }
  72% {
    transform: rotateZ(-108deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(5) {
  transform: rotateZ(144deg);
  animation: rotateCircle5 2s infinite linear;
  z-index: 5;
}
@keyframes rotateCircle5 {
  20% {
    opacity: 0;
  }
  20.0004% {
    opacity: 1;
    transform: rotateZ(-108deg);
  }
  27% {
    transform: rotateZ(-144deg);
  }
  77% {
    transform: rotateZ(-144deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(6) {
  transform: rotateZ(180deg);
  animation: rotateCircle6 2s infinite linear;
  z-index: 4;
}
@keyframes rotateCircle6 {
  25% {
    opacity: 0;
  }
  25.0005% {
    opacity: 1;
    transform: rotateZ(-144deg);
  }
  32% {
    transform: rotateZ(-180deg);
  }
  82% {
    transform: rotateZ(-180deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(7) {
  transform: rotateZ(216deg);
  animation: rotateCircle7 2s infinite linear;
  z-index: 3;
}
@keyframes rotateCircle7 {
  30% {
    opacity: 0;
  }
  30.0006% {
    opacity: 1;
    transform: rotateZ(-180deg);
  }
  37% {
    transform: rotateZ(-216deg);
  }
  87% {
    transform: rotateZ(-216deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(8) {
  transform: rotateZ(252deg);
  animation: rotateCircle8 2s infinite linear;
  z-index: 2;
}
@keyframes rotateCircle8 {
  35% {
    opacity: 0;
  }
  35.0007% {
    opacity: 1;
    transform: rotateZ(-216deg);
  }
  42% {
    transform: rotateZ(-252deg);
  }
  92% {
    transform: rotateZ(-252deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(9) {
  transform: rotateZ(288deg);
  animation: rotateCircle9 2s infinite linear;
  z-index: 1;
}
@keyframes rotateCircle9 {
  40% {
    opacity: 0;
  }
  40.0008% {
    opacity: 1;
    transform: rotateZ(-252deg);
  }
  47% {
    transform: rotateZ(-288deg);
  }
  97% {
    transform: rotateZ(-288deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.preloader div:nth-child(10) {
  transform: rotateZ(324deg);
  animation: rotateCircle10 2s infinite linear;
  z-index: 0;
}
@keyframes rotateCircle10 {
  45% {
    opacity: 0;
  }
  45.0009% {
    opacity: 1;
    transform: rotateZ(-288deg);
  }
  52% {
    transform: rotateZ(-324deg);
  }
  102% {
    transform: rotateZ(-324deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}

@keyframes tadatada {
  from {
    transform: scale3d(1, 1, 1);
  }
  10% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  60% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  70% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
