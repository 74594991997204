.Progression-contents-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0.5;
  color: #878787;
  font-size: 1.5vh;
}

.Progression-contents-right-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  box-shadow: 24px 1px 0px 0px rgb(0 0 0 / 30%) inset;
}

.Progression-contents-right-outer {
  display: flex;
  align-items: flex-end;
  width: 25vw;
  height: 1.5vh;
  border-radius: 50px;
  background: #fdf7e6;
  border: none;
  overflow-y: hidden;
}

.Progression-contents-right-inner {
  position: relative;
  height: 100%;
  background: #55acdb;
  border-radius: 25px;
  transition: width 100ms ease-out;
  border: none;
}
