.television-enigma-remote-object button,
.television-enigma-icons-grid button {
  padding: 0;
  border: none;
  background-color: transparent;
}

.television-enigma-container {
  flex-basis: 50%;
}

.television-enigma-container-images {
  /* Need a relative position to place a child image
  with absolute position */
  position: relative;
  top: 0;
  left: 0;
  margin: 2rem;
}

.television-enigma-container-tvbackground {
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
  /* Added for .television-enigma-animation to be at the right size */
  width: 100%;
  height: 100%;
  display: block;
}

.television-enigma-animation {
  position: absolute;
  bottom: 0;
  /* Slightly smaller than the TV */
  transform: scale(0.95, 0.91);
  transform-origin: 48% 30%;
  width: 100%;
  height: 100%;
  /* On top of the TV image */
  z-index: 2;
}

.television-enigma-remote {
  /* Define a fixed width to avoid child elements being shifted */
  width: 381px;
}

.ModalEnigma_description-contents .television-enigma-remote-text,
.television-enigma-remote .television-enigma-text {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.television-enigma-remote-object {
  height: 335px;
  /* Image of the empty remote control in background to place the elements above it  */
  background-image: url('../../../../../../img/obesity/tv/remote-obesity.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.television-enigma-remote-arrows-switch {
  position: relative;
  right: -1rem;
  bottom: 3rem;
}

.television-enigma-remote-arrows-numbers {
  position: relative;
  bottom: -3rem;
  left: -1.5rem;
  /* Define fixed width to avoid resizing with screen size */
  width: 100px;
}

.television-enigma-remote-arrows {
  position: relative;
  bottom: -4rem;
}

.television-enigma-remote-arrows-left {
  position: relative;
  left: 0.75rem;
  top: 0.75rem;
}

.television-enigma-remote-arrow {
  width: 85%;
}

.television-enigma-remote-arrows-up {
  position: relative;
  right: 0.25rem;
  bottom: 2rem;
}

.television-enigma-remote-arrows-ok {
  position: relative;
  right: 3.5rem;
}

.television-enigma-remote-arrows-right {
  position: relative;
  right: 3.75rem;
  top: 0.75rem;
}

.television-enigma-remote-arrows-down {
  position: relative;
  right: 8.85rem;
  top: 1.75rem;
}

.television-enigma-remote-pad {
  position: relative;
  bottom: -6rem;
  left: 0.75rem;
  /* Define fixed width to avoid resizing with screen size */
  width: 95px;
}

.television-enigma-remote-menu {
  position: relative;
  bottom: -7rem;
  right: 3.25rem;
}

/* Media query when the size of the TV becomes smaller than the remote control*/
@media screen and (max-width: 1600px) {
  .television-enigma-remote-object {
    /* Scale is used to avoid shifting the elements inside the remove control */
    transform: scale(0.8);
    transform-origin: left;
  }

  .television-enigma-text {
    /* Translate is used to move the text closer to the scaled remote control */
    transform: translate(0, 34px);
    transform-origin: left;
  }

  .television-enigma-container {
    flex-basis: 60%;
  }
}

@media screen and (max-width: 1200px) {
  .television-enigma-remote-object {
    transform: scale(0.7);
    transform-origin: left;
  }

  .television-enigma-text {
    transform: translate(0, 50px);
    transform-origin: left;
  }

  .ModalEnigma_description-contents .television-enigma-remote-text,
  .television-enigma-remote .television-enigma-text {
    font-size: 1rem;
  }

  .television-enigma-container {
    flex-basis: 70%;
  }

  @media screen and (max-width: 1000px) {
    .television-enigma-container {
      flex-basis: 100%;
    }

    .television-enigma-container-images .television-enigma-tv-app-images {
      width: 90%;
    }
  }
}

.television-enigma-icons-grid {
  display: grid;
  /* Define the size of the grid cells to avoid the size to change
  when navigating between the icons */
  grid-template-rows: repeat(2, 25%);
  grid-template-columns: repeat(5, 20%);
  gap: 1rem 0;
  align-items: center;
  align-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.85);
  z-index: 1;
}

.television-enigma-tv-welcome {
  grid-area: 1 / 1 / 4 / 3;
  width: 100%;
  z-index: 2;
}

.television-enigma-tv-picture-app {
  grid-area: 1 / 3 / 1 / 4;
  z-index: 2;
}

.television-enigma-tv-app-images {
  width: 80%;
}

.television-enigma-tv-sound-app {
  grid-area: 1 / 4 / 1 / 5;
  z-index: 2;
}

.television-enigma-tv-tv-app {
  grid-area: 1 / 5 / 1 / 6;
  z-index: 2;
}

.television-enigma-tv-channel-app {
  grid-area: 2 / 3 / 2 / 4;
  z-index: 2;
}

.television-enigma-tv-timer-app {
  grid-area: 2 / 4 / 2 / 5;
  z-index: 2;
  margin-top: 1rem;
}

.television-enigma-tv-source-app {
  grid-area: 2 / 5 / 2 / 6;
  z-index: 2;
}

.television-enigma-timer-grid {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: repeat(4, 25%);
  align-items: center;
  justify-items: start;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.85);
  z-index: 1;
}
