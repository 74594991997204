.ChatBot {
  z-index: 10;
  position: absolute;
  left: 2%;
  bottom: 2%;
  display: flex;
  flex-direction: column;
  width: 40%;
  font-size: 1vw;
}

.ChatBot-img {
  z-index: 101;
  width: 20%;
  border: none;
  background-color: transparent;
}

.ChatBot-img img {
  z-index: 101;
  height: 100px;
  width: 100px;
}

.ChatWindow {
  background-color: #6f9bbf;
  border-radius: 10px;
  padding: 0.1%;
  margin-bottom: 1%;
}

.ChatBot-dialog-text {
  height: 50ex;
  overflow-y: scroll;
  border-radius: 10px;
  background-color: white;
  padding: 1%;
  margin: 1%;
}

.ChatBot-Form {
  display: flex;
  width: 100%;
}

.ChatBot-input {
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ChatBot-Question {
  display: flex;
  justify-content: center;
  border: solid 1px;
  border-radius: 75px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 50px;
  padding: 2%;
  animation-name: bounceIn;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  max-width: 70%;
  min-width: 10%;
  text-align: end;
  word-break: break-word;
}

.ChatBot-Question-Container {
  display: flex;
  justify-content: flex-end;
}

.ChatBot-Response-Container {
  display: flex;
  justify-content: flex-start;
}

.ChatBot-Response {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: solid 1px;
  border-radius: 75px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 50px;
  padding: 2%;
  max-width: 70%;
  min-width: 10%;
  animation-name: bounceIn;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  word-break: break-word;
}

.ChatBot-Response-Feedback {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  font-size: smaller;
  margin: 1%;
}

.ChatBot-Response-Feedback button {
  border: none;
  background-color: inherit;
  text-decoration: underline;
}

.ChatBot-Response-Feedback p {
  margin: 2%;
  text-decoration: underline;
}

.ChatBot-dialog-text::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  border-radius: 100px;
}

.ChatBot-dialog-text::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ChatBot-input-Text {
  width: 100%;
  resize: none;
  margin: 5%;
}

.ChatBot-input select {
  width: 100%;
  height: 70px;
}

.ChatBot-input-Text::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  border-radius: 100px;
}

.ChatBot-input-Text::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}

.ChatBot-Buttons {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1200px) {
  .ChatBot-img img {
    height: 50px;
    width: 50px;
  }
}
