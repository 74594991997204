.chestDiv img {
  position: absolute;
  z-index: 3;
  height: 6.5%;
  top: 66.5%;
  left: 49%;
}

.Chest404 img {
  position: absolute;
  height: 1.6%;
  top: 70%;
  left: 50.5%;
}

.modal_chest_img {
  width: 30vw;
}

.chestCode img {
  position: relative;
}

.buttonsChestCode {
  position: absolute;
  top: 54%;
  left: 43%;
}

.buttonChestCode1 {
  color: #d1baee;
  background-color: #294780;
  height: 23px;
  width: 23px;
  text-align: center;
  font-family: unset;
  font-size: 0.9rem;
  margin: 1%;
  border-radius: 20%;
  margin-right: 0.5vw;
  padding: 0%;
}

.buttonChestCode2 {
  color: #d1baee;
  background-color: #294780;
  height: 23px;
  width: 23px;
  text-align: center;
  font-family: unset;
  font-size: 0.9rem;
  margin: 1%;
  border-radius: 20%;
  margin-right: 0.5vw;
  padding: 0%;
}

.buttonChestCode3 {
  color: #d1baee;
  background-color: #294780;
  height: 23px;
  width: 23px;
  text-align: center;
  font-family: unset;
  font-size: 0.9rem;
  margin: 1%;
  border-radius: 20%;
  margin-right: 0.5vw;
  padding: 0%;
}

.buttonChestCode4 {
  color: #d1baee;
  background-color: #294780;
  height: 23px;
  width: 23px;
  text-align: center;
  font-family: unset;
  font-size: 0.9rem;
  margin: 1%;
  border-radius: 20%;
  margin-right: 0.5vw;
  padding: 0%;
}

.chestItem {
  height: 50%;
  width: 30%;
}
