.testing {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .camera {
    position: relative;
    .video {
      background-color: #353b48;
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: cover;
      border-radius: 5px;
    }
    .input-pseudo {
      text-decoration: none;
      border: none;
      position: absolute;
      bottom: 40px;
      color: white;
      border-radius: 5%;
      background-color: rgba(0, 0, 0, 0.4392156863);
      padding: 1%;
      font-size: x-large;
      margin: 0 2vw;
    }
  }

  .result {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    transition: 0.4s;
  }

  .result-hasPhoto {
    left: 0;
  }

  video {
    border-radius: 2%;
    background-color: #353b48;
    width: '1280px';
    height: '720px';
  }
  .video-button,
  .audio-button,
  .sound-button {
    position: absolute;
    bottom: 0px;
    appearance: none;
    border: none;
    outline: none;
    transition: 0.4s;
    cursor: pointer;
    height: 7.5vh;
    width: 10%;
    background-color: transparent;
    text-decoration: none;
    border: none;
    background-repeat: no-repeat;
    transition: 0.4s;
  }

  .video-button.activated {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fcamera-icon-activated.svg?alt=media&token=95b9efe5-3ac4-4793-a7b7-f5db3ba3f1cd');
  }

  .video-button {
    left: 35%;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fcamera-icon.svg?alt=media&token=4fc4dcd3-9d4d-4544-ba71-cae5d4e0bf5c');
    &:hover {
      left: 35%;
      background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fcamera-icon-hover.svg?alt=media&token=cb73a459-6e5a-41f5-be85-adc10d9c2eef');
    }
  }

  .audio-button.activated {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fmicro-icon-activated.svg?alt=media&token=2c2184ae-9046-46e7-8f0c-9c298ecc2e87');
  }

  .audio-button {
    left: 45%;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fmicro-icon.svg?alt=media&token=d9ba2801-2c7b-4300-a4eb-a497fb5e8d6d');
    &:hover {
      background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fmicro-icon-hover.svg?alt=media&token=306308bb-78fd-401e-9855-d012782b5160');
    }
  }

  .sound-button.activated {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fsound-icon-activated.svg?alt=media&token=48c1a19b-65da-4de7-9ac3-79d677eaa985');
  }

  .sound-button {
    left: 55%;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fsound-icon.svg?alt=media&token=c25a81b7-029e-4a20-8c2d-89a7ad0ec0fc');
    &:hover {
      background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fsound-icon-hover.svg?alt=media&token=848676c4-1317-48a9-b126-b379cd91d856');
    }
  }
}
