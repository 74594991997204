.HelpDoc0 {
  position: absolute;
  display: flex;
  background-color: #fbf7f7;
  padding: 1.125vw 1.5vw;
  font-size: 1.25em;
  filter: drop-shadow(8px 5px 3px rgba(93, 93, 93, 0.5));
  border-radius: 1em;
  height: 20vh;
  width: 40vw;
  z-index: 2;
}

.HelpDoc0:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 2.2vw 0 5.5vw 5.5vw;
  border-color: #fbf7f7 transparent transparent transparent;
  bottom: 0vh;
  left: -3vw;
  margin-left: -2.5vw;
}

.HelpDoc0_text {
  display: flex;
  position: relative;
  font-size: 1vw;
  padding-top: 2vh;
}

.HelpDoc2 {
  position: absolute;
  display: flex;
  background-color: #fbf7f7;
  padding: 1.125em 1.5em;
  font-size: 1.25em;
  filter: drop-shadow(8px 5px 3px rgba(93, 93, 93, 0.5));
  border-radius: 1em;
  height: 20vh;
  width: 40vw;
  z-index: 2;
}

.HelpDoc2:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 3vw 6vh 0;
  border-color: transparent #fbf7f7 transparent transparent;
  bottom: -5.8vh;
  right: 11vw;
  margin-right: -2.5vw;
}

.HelpDoc2_text {
  display: flex;
  position: relative;
  font-size: 1vw;
  padding-top: 0.6vh;
}

.HelpDoc4 {
  position: absolute;
  display: flex;
  background-color: #fbf7f7;
  padding: 1.125em 1.5em;
  font-size: 1.25em;
  filter: drop-shadow(8px 5px 3px rgba(93, 93, 93, 0.5));
  border-radius: 1em;
  left: 21%;
  top: 14%;
  height: 20vh;
  width: 40vw;
  z-index: 2;
}

.HelpDoc4:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: transparent #fbf7f7;
  border-width: 1.2vw 0 1.5vw 5.5vw;
  top: 13vh;
  left: 40vw;
  margin-top: -1vw;
}

.HelpDoc4_text {
  display: flex;
  position: relative;
  font-size: 1vw;
  padding-top: 0.8vh;
}

.HelpDoc6 {
  position: absolute;
  display: flex;
  background-color: #fbf7f7;
  padding: 1.125em 1.5em;
  font-size: 1.25em;
  filter: drop-shadow(8px 5px 3px rgba(93, 93, 93, 0.5));
  border-radius: 1em;
  left: 40.5%;
  top: 11%;
  height: 20vh;
  width: 40vw;
  z-index: 2;
}

.HelpDoc6:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 3vw 6vh 0;
  border-color: transparent #fbf7f7 transparent transparent;
  bottom: -5.5vh;
  right: 4vw;
  margin-right: -2vw;
}

.HelpDoc6_text {
  display: flex;
  position: relative;
  font-size: 0.95vw;
  padding-top: 0.8vh;
}

.ObjectsNeeded {
  position: relative;
  text-align: center;
  letter-spacing: 0.05vw;
  padding-top: 0.8vh;
  padding-bottom: 1.35vh;
  font-size: 1vw;
}

.BoxObject {
  width: 7vw;
  height: 10vh;
  margin: 2px;
  border-radius: 2vh;
  background-image: linear-gradient(-90deg, #b8b7b7, #c8c5c2);
  box-shadow: inset 0px 0px 10px 0.01px #a6a6a6;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.close {
  position: fixed;
  width: 2vw;
  height: 2vw;
}

.close::before,
.close::after {
  position: absolute;

  right: -2vw;
  top: -2vh;
  content: ' ';
  height: 1vw;
  width: 0.15vw;
  background-color: #555;
}
.close::before {
  transform: rotate(45deg);
}
.close::after {
  transform: rotate(-45deg);
}

.close:hover::after,
.close:hover::before {
  background-color: #333;
}

.close:active::after,
.close:active:before {
  opacity: 0.1;
}

.SideBoxBubbleSpeechRPS {
  border-left: 1px solid;
  border-left-color: rgb(219, 213, 213);
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}
.image-HelpDoc {
  width: 5vw;
  height: 9vh;
  z-index: 2;
}
.boxContainerRPS {
  display: flex;
  flex-direction: row;
}
