.Phone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.Phone_enigma {
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 520px;
  width: 295px;
  border-radius: 50px;
  background-color: #f6f4f3;
  border: 4px solid #d8d9d8;
}

.Phone_bar {
  background-color: #272929;
  height: 4px;
  width: 43px;
  border-radius: 10px;
  margin: 1.5rem 0;
}

.Phone_screen-content {
  display: flex;
  flex-direction: column;
  height: 75%;
  width: 260px;
}

.Phone_screen-content.menu {
  background-color: #e77820;
}
.Phone_screen-content.app {
  background-color: #ededed;
}

.Phone_screen-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1;
  margin: 10px 5px;
}

.Phone_screen-content-header.menu {
  color: white;
}

.Phone_screen-content-header.app {
  color: #272929;
}

.Phone_touch {
  background-color: transparent;
  border: 1px solid #aaaaaa;
  height: 45px;
  width: 45px;
  border-radius: 15px;
  margin: 1rem;
}

.Phone_screen-content-buttons {
  margin: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex: 3 1;
}

.Phone_screen-content-button {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0.5rem 0;
}

.Phone_screen-app {
  border: none;
  padding: 15px;
  border-radius: 10px;
}

.Phone_screen-app.violet {
  background-color: #5961a5;
}

.Phone_screen-app.grey {
  background-color: #d5d4d8;
}

.Phone_screen-app.white {
  background-color: #ffffff;
}

.Phone_screen-app.greylight {
  background-color: #d5d5d2;
}

.Phone_screen-app.bluelight {
  background-color: #31b5e9;
}

.Phone_screen-app.greenlight {
  background-color: #74ba5b;
}

.Phone_screen-content-button p {
  font-size: small;
  margin-top: 5px !important;
}

.Phone_screen-content-footer {
  flex: 1 1;
  background-color: #f3c27f;
}
