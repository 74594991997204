.Advice {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  transition-duration: 0.5s;
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  padding: 10% 20%;
}

.Advice_content {
  padding: 5%;
  border-radius: 10px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.Advice_content_arrow {
  margin: -3%;
  transform: rotate(-90deg);
  animation: slide 2.5s ease-in-out 0s infinite normal;
  height: 100px;
}

.Advice_content_header {
  display: flex;
  justify-content: center;
}

.Advice_content_header img {
  height: 8vh;
}

.Advice_content_advice {
  border-radius: 20px;
  margin: 30px 0;
  font-weight: 600;
  line-height: 1.8;
  font-size: 1.2vw;
  overflow-y: scroll;
}

.Advice_content_advice p {
  padding-right: 5%;
}

.Advice_content_advice::-webkit-scrollbar {
  background-color: transparent !important;
  border-radius: 100px;
  border: 1px solid gray;
  margin: 10%;
}

.Advice_content_advice::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 100px;
}

.Advice_content_advice h1 {
  width: 100%;
  justify-content: space-evenly;
  color: #d66339;
  font-weight: bold;
}
