.intro {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  padding: 10% 20%;
}

.intro-first,
.intro-second {
  text-align: center;
}

.intro-second p,
.intro-first h1 {
  text-align: center;
}
