.enigma-header {
  position: absolute;
  top: 0%;
  left: 4px;
  width: 42%;

  div {
    position: relative;
  }

  img {
    max-width: 100%;
  }
}
