.Camera {
  padding: 5px;
}

.Camera p {
  color: #272929 !important;
}

.Camera_firstTitle {
  text-align: center;
}

.Camera_secondTitle {
  margin: 10px 0 !important;
}

.Camera_secondTitle,
.Camera_botton-contents-subtitle {
  font-size: smaller;
}

.Camera_title {
  font-weight: 600 !important;
}
