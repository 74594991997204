.Zoom--day,
.Zoom--night {
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  top: 0;
  z-index: 10;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background-size: cover;
}
