/* REFACTO Page 404 and delete Urania_Czech.woff */
@font-face {
  font-family: 'Urania';
  src: url('Urania_Czech.woff') format('woff');
}

/* MUG */

.Align-Center {
  align-self: center;
}

/* MODALS GENERIC */

.ModalTricky-Background p,
.ModalTricky-Background-Time p,
.ModalTricky-Background2 p,
.ModalTricky-Background-Position p,
.ModalTrickyObject-Background p,
.ModalTricky-Background-time-end p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
}

.ModalTricky-Background {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 51;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Background h3 {
  color: #fff;
  margin-bottom: 0px;
}

.ModalTricky-Background-time-end {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  justify-content: center;
  align-items: center;
  top: -180%;
  left: -4%;
  width: 80vw;
  height: 86vh;
  z-index: 200;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Background-Time {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  backdrop-filter: blur(5px);
}

.ModalTricky-NoClick {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  cursor: url('../../img/pink.cur'), default;
}

.ModalTricky-NoClick-Admin {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 20%;
  width: 100%;
  height: 100%;
  z-index: 50;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  cursor: url('../../img/pink.cur'), default;
}

.ModalTricky-NoClick2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 20%;
  width: 100%;
  height: 100%;
  z-index: 50;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  cursor: url('../../img/unavailable.cur'), default;
}

.No-Click-Message {
  position: absolute;
}

.Prevention-Message {
  padding: 20px;
}

.ModalTricky-button {
  width: 100%;
}

.ModalTricky-button button {
  position: absolute;
  transform: translateX(-60%);
}

.ModalTrickyObject-Background {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 7%;
  left: 20%;
  width: 60%;
  height: 76.3%;
  z-index: 5;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Background-Position {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 6.8%;
  left: 20.1%;
  width: 59.7%;
  height: 74%;
  z-index: 5;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Background2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  height: 70%;
  width: 70%;
  z-index: 3;
}

.ModalTricky-Container-Email {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 3;
}

/* PAUSE */

.ModalTricky-pause {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  height: 70%;
  width: 70%;
}

.ModalTricky-pause h1 {
  width: 100%;
  color: #fff;
}
.ModalTricky-pause .dots {
  position: relative;
  width: 100%;
  -webkit-animation: move-2 3s linear infinite;
  animation: move-2 3s linear infinite;
  -webkit-animation-delay: -400ms;
  animation-delay: -400ms;
}
.ModalTricky-pause .dot {
  display: inline-block;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background: #fff;
  -webkit-animation: move 3s cubic-bezier(0.1, 0.8, 0.9, 0.1) infinite;
  animation: move 3s cubic-bezier(0.1, 0.8, 0.9, 0.1) infinite;
}
.ModalTricky-pause .dot:nth-of-type(2) {
  -webkit-animation-delay: -100ms;
  animation-delay: -100ms;
}
.ModalTricky-pause .dot:nth-of-type(3) {
  -webkit-animation-delay: -200ms;
  animation-delay: -200ms;
}
.ModalTricky-pause .dot:nth-of-type(4) {
  -webkit-animation-delay: -300ms;
  animation-delay: -300ms;
}
.ModalTricky-pause .dot:nth-of-type(5) {
  -webkit-animation-delay: -400ms;
  animation-delay: -400ms;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: translateX(-10em);
    transform: translateX(-10em);
    opacity: 0;
  }
  10%,
  70% {
    opacity: 0.9;
  }
  80%,
  100% {
    -webkit-transform: translateX(10em);
    transform: translateX(10em);
    opacity: 0;
  }
}

@keyframes move {
  0% {
    -webkit-transform: translateX(-10em);
    transform: translateX(-10em);
    opacity: 0;
  }
  10%,
  70% {
    opacity: 0.9;
  }
  80%,
  100% {
    -webkit-transform: translateX(10em);
    transform: translateX(10em);
    opacity: 0;
  }
}
@-webkit-keyframes move-2 {
  0% {
    -webkit-transform: translateX(4em);
    transform: translateX(4em);
  }
  100% {
    -webkit-transform: translateX(-4em);
    transform: translateX(-4em);
  }
}
@keyframes move-2 {
  0% {
    -webkit-transform: translateX(4em);
    transform: translateX(4em);
  }
  100% {
    -webkit-transform: translateX(-4em);
    transform: translateX(-4em);
  }
}

/* ... */

.ModalTricky-TimeEnd {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 70%;
}

.ModalTricky-Animation {
  width: 247.359px;
  height: 187.219px;
  margin-bottom: 70px;
}

.ModalTricky-Container-Powerchord {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 60%;
}

.ModalTricky-WrongObject {
  width: 100%;
}

.ModalTricky-WrongObject img {
  animation: showwrongitem 0.5s ease;
  animation-fill-mode: forwards;
}

/* MODAL INTRO */

.ModalTricky-Intro {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Intro-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ModalTricky-Intro-Card {
  display: flex;
  flex-direction: column;
  font-size: small;
  text-align: left;
  color: #fff;
  font-family: 'Urania', Arial, Helvetica, sans-serif;
  padding: 0 20px;
}

.ModalTricky-Intro-Text {
  display: flex;
  flex-direction: column;
  line-height: 1.3rem;
  margin-top: 10px;
}

.ModalTricky-Intro-Img img {
  object-fit: cover;
  height: 400px;
  width: 350px;
}

/* MODAL LETTER */

.opened-letter {
  height: 90% !important;
}

.Letter-Open {
  width: 250%;
  margin-bottom: 1px !important;
}

.Letter-Text {
  width: 165%;
  margin-bottom: 0 !important;
  position: relative;
}

.Letter-Text-Cont {
  position: absolute;
  top: 30%;
  width: 20%;
}

.Letter-Text-Cont p {
  color: #000000;
  text-align: left;
  font-weight: 500;
  font-size: 1.1rem;
}

.ModalTricky-p2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.ModalTricky-p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #fff;
}

.ModalObjectTricky-p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.ModalObjectTricky-p img {
  width: 20%;
  margin-top: 30px;
  margin-bottom: 0;
}

.ModalObjectTricky-p .MugImg {
  width: 80%;
  margin-left: 70px;
}

.ModalObject-Button {
  display: flex;
}

.ModalTricky-Emotion-Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: left;
  flex: auto;
}

.ModalTricky-Emotion-Card-Text {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 81px;
  left: 127px;
  width: 74%;
  text-align: center;
}

.ModalTricky-Emotion-Card h4 {
  color: #fff;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.2vw;
}

.ModalTricky-Emotion-Card p {
  color: #fff;
  margin-bottom: 0.5vw !important;
  text-align: left;
  font-size: 0.9vw;
  text-align: center;
}

.Emotion-Card-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.Emotion-Card-Container h2 {
  margin-top: 2%;
  margin-bottom: 20%;
}

.Emotion-Card-Container-Title {
  font-size: 3vw !important;
  font-weight: 500;
}

.ModalTricky-Emotion-Card img {
  width: 95%;
  align-self: center;
  margin-bottom: 0px !important;
  position: relative;
}

.EmotionCont {
  display: flex;
}

.Emotion-Card {
  position: relative;
  width: 100%;
}

.Emotion-Card-Title-1 {
  position: absolute;
  top: 28px;
  left: 100px;
}

.Emotion-Card-Text-1 {
  position: absolute;
  top: 95px;
  left: 42px;
  width: 70%;
}

.Emotion-Card-Title-2 {
  position: absolute;
  top: 28px;
  left: 100px;
}

.Emotion-Card-Text-2 {
  position: absolute;
  top: 90px;
  left: 42px;
  width: 70%;
}

.fa-check-circle {
  margin: 5px 5px 0px 0px !important;
}

.Hide-Modal {
  display: none !important;
}

.ModalTricky-p2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.ModalTricky-powerchord {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.ModalTricky-powerchord img {
  width: 100%;
}

.ModalTricky-Computer {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 300px;
  background: #23345a;
  border: 10px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password-Success {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid green;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Password-Error {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 400px;
  background: #23345a;
  border: 10px solid red;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px;
}

.ModalTricky-Computer-Email {
  display: flex;
  padding: 0.5vw;
  flex-direction: column;
  justify-content: center;
  width: 42vw;
  background: #23345a;
  border: 0.5vw solid #000000;
  border-radius: 2vw;
}

.ModalTricky-Computer-Email-p {
  background: #fff;
  border-radius: 1vw;
}

.ModalTricky-Computer-Password label {
  color: #fff;
  margin-right: 10px;
}

.ModalTricky-Computer-Password input {
  margin-bottom: 40px;
}

.Generic {
  width: 40%;
  align-self: center;
}

.ModalTricky-Computer-Password .Error {
  color: red;
  font-weight: bold;
}

.ModalTricky-Computer-Password .Success {
  color: green;
  font-weight: bold;
}

:root {
  --letter-width: 0.7vw;
}

.ModalTricky-Computer-Email p {
  font-family: monospace !important;
  margin: 20px !important;
  font-weight: 500 !important;
  font-size: 1vw;
  color: #23345a !important;
  line-height: 1.3vw;
}

.ModalTricky-Computer-Email .Button-Email {
  display: flex;
  justify-content: center;
}

.ModalTricky-Computer-Email .ValidButton,
.ModalTricky-Computer-Email .CancelButton {
  /* margin: 20px; */
  z-index: 1;
  cursor: pointer;
}

.ModalTricky-Computer-Email input {
  font-family: monospace !important;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 1vw;
  font-weight: 500;
  color: steelblue;
  letter-spacing: calc(var(--letter-width) * 0.1314);
}

.word1 {
  width: calc(
    var(--letter-width) * 5
  ); /* 5x0.7 = nbrCharacters x background-size */
}

.word1_ES {
  width: calc(var(--letter-width) * 6);
}

.word2 {
  width: calc(var(--letter-width) * 12);
}

.word3 {
  width: calc(var(--letter-width) * 8);
}

.word3_ES {
  width: calc(var(--letter-width) * 7);
}

.word3_EN {
  width: calc(var(--letter-width) * 6);
}

.word4 {
  width: calc(var(--letter-width) * 15);
}

.word4_ES {
  width: calc(var(--letter-width) * 16);
}

.dashed-gradient {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 70%,
    transparent 30%
  ); /* dash = 70% of image width, space = 30% */
  background-position: left bottom;
  background-repeat: repeat-x; /* repeat on x-axis */
  background-size: var(--letter-width) 2px; /* width = one dash (70%) + one space (30%) | height = 10% of input height */
}

.word1:focus,
.word2:focus,
.word3:focus,
.word4:focus {
  outline: none;
  background-color: Lavender;
}

.PowerChord {
  width: 100%;
  position: relative;
}

.textPowerChord {
  position: absolute;
  top: 10%;
  font-size: 2rem;
}

.btnPowerChord {
  font-size: 1.5rem;
  position: absolute;
  top: 30%;
  left: 50%;
}

.Img-vision {
  width: 60%;
  align-self: center;
}
/* BOARD */

.ModalTricky-Board-Container {
  background-image: url('../../img/office/Tableau_background_etire_t0nqm9.svg');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.Board-Melanie img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 18%;
  left: 34.5%;
  cursor: pointer;
}

.Board-Chloe img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 27%;
  left: 61%;
  cursor: pointer;
}

.Board-Marion img {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 55%;
  left: 41%;
  cursor: pointer;
}

.Board-Pierre img {
  position: absolute;
  z-index: 2;
  height: 21%;
  top: 69.5%;
  left: 64.5%;
  cursor: pointer;
}

/*----TELEPHONE----*/

.ModalTricky-Phone-Number form {
  position: relative;
}

.ModalTricky-Phone-Number img {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.phone-number-input {
  position: absolute;
  top: 7.5%;
  left: 31.5%;
  transform: skew(-18deg, -2deg);
  background-color: transparent;
  font-size: 1.6vw;
  width: 23%;
  height: 10%;
  border-style: none;
}

.phone-number-inputEN {
  position: absolute;
  top: 7.5%;
  left: 31.5%;
  transform: skew(-18deg, -2deg);
  background-color: transparent;
  font-size: 1.4vw;
  width: 23%;
  height: 10%;
  border-style: none;
}

.phone-number-input:focus {
  outline: none;
}

.container_pad {
  display: inline-flex;
  flex-wrap: wrap;
  width: 13vw;
  position: absolute;
  top: 26%;
  left: 29%;
  transform: skew(-6deg);
  white-space: nowrap;
}

.phone_button {
  position: relative;
  font-size: 1vw;
  font-weight: 700;
  background-color: black;
  border-radius: 8px;
  display: inline-block;
  margin: 3px;
}

.inner-phone-button {
  text-align: center;
  width: 3vw;
  height: 2vw;
  color: black;
  text-decoration: none;
  display: inline-block;
  background-color: white;
  border-radius: inherit;
  border: 2px solid black;
  padding: 0 5px;
  text-transform: uppercase;
  transform: translate3d(-5px, -4px, 0);
  transition: transform 80ms ease;
  transition-delay: 50ms;
}

.inner-phone-button:active {
  transform: translate3d(0, 0, 0);
  transition: transform 80ms ease;
}

.inner-phone-button:focus {
  outline: none;
}

.fa-backspace {
  color: black;
}

/*------------------*/

#PostitMelanie {
  position: absolute;
  z-index: 2;
  height: 22%;
  top: 22%;
  left: 69%;
}

#PostitChloe {
  position: absolute;
  z-index: 2;
  height: 19%;
  top: 27%;
  left: 76.3%;
}

#PostitMarion {
  position: absolute;
  z-index: 2;
  height: 19%;
  top: 37%;
  left: 70.5%;
}

#PostitPierre {
  position: absolute;
  z-index: 2;
  height: 18%;
  top: 44.3%;
  left: 77%;
}

.Board-Buttons {
  display: flex;
}

/* ANIMATIONS */

.ItemToShow,
.ItemToShow2Objects,
.ItemToShowCushionKnife,
.ItemToSHowPizzaKnife .ItemToShowR1,
.ItemToShowIndice,
.ItemToShowIndiceModal,
.ItemToShowE2,
.ItemToShowKey {
  height: 60%;
  width: 100%;
}

.ItemToShow img {
  animation: showitem 0.5s ease;
  animation-fill-mode: forwards;
  height: 100%;
}

.ItemToShow2Objects {
  display: flex;
}

.ItemToShow2Objects img {
  display: flex;
  animation: showitem 0.5s ease;
  animation-fill-mode: forwards;
  height: 100%;
}

.ItemToShowCushion {
  height: 60%;
  width: 100%;
  position: relative;
  /* transition: transform 200ms ease-in-out; */
}

.ItemToShowCushion img {
  animation: showitem 0.5s linear;
  animation-fill-mode: forwards;
  height: 100%;
}

.ItemToShowCushionCut {
  height: 60%;
  width: 100%;
  position: relative;
}

.ItemToShowCushionCut img {
  animation: getout 3s;
  opacity: 0;
  height: 100%;
}

.ItemToShowCushionKnife img {
  position: absolute;
  right: 70%;
  bottom: 40%;
  height: 80%;
  opacity: 0;
  transform: rotate(135deg);
  animation: cutcushion 1s 0.5s;
}

.ItemToShowR1 img {
  position: absolute;
  left: 60%;
  bottom: 10%;
  height: 100%;
}

.ItemToShowIndice img {
  position: absolute;
  right: 60%;
  bottom: 10%;
  height: 100%;
}

.ItemToShowE2 img {
  position: absolute;
  left: 60%;
  bottom: 30%;
  width: 30%;
}

.ItemToShowKey img {
  position: absolute;
  right: 65%;
  bottom: 30%;
  width: 20%;
}

.ItemToShowIndiceModal img {
  width: 50%;
}

.ItemToShowLight img {
  width: 50%;
}

.ItemToShowLight {
  height: 65%;
  width: 100%;
  position: relative;
}

.ModalTricky-Container-Light {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  height: 90%;
  width: 70%;
  z-index: 3;
}

.zoomleft {
  animation: zoomleft 0.5s ease;
  animation-fill-mode: forwards;
  position: absolute;
  display: block;
  top: 61%;
  left: 41%;
  height: 9%;
  opacity: 1;
  z-index: 6;
}

.zoomleft img {
  height: 100%;
}

@keyframes getout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes cutpizza {
  0% {
    right: 70%;
    opacity: 1;
  }
  100% {
    right: 40%;
    opacity: 1;
  }
}

@keyframes cutcushion {
  0% {
    right: 70%;
    opacity: 1;
  }
  12.5% {
    right: 40%;
    opacity: 1;
  }
  25% {
    right: 70%;
    opacity: 1;
  }
  50% {
    right: 40%;
    opacity: 1;
  }
  62.5% {
    right: 70%;
    opacity: 1;
  }
  75% {
    right: 40%;
    opacity: 1;
  }
  100% {
    right: 70%;
    opacity: 0;
  }
}

@keyframes showitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}

@keyframes showwrongitem {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 35%;
    opacity: 1;
  }
}

@keyframes zoomleft {
  from {
    opacity: 1;
    z-index: 10;
  }
  to {
    position: absolute;
    top: 20%;
    left: 0%;
    opacity: 0;
    display: none;
  }
}
