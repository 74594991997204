.plutchik-wheel-type {
  color: white;
  font-size: xx-large;
  background-color: rgba(0, 0, 0, 0.2901960784);
  border-radius: 10px;
  padding: 5px 30px;
  margin: 0 15%;
  img {
    height: 250px;
    border-radius: 5%;
  }
}
