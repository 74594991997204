.lamp-image,
.lamp {
  height: 64%;
}

.lamp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
