.Modal p {
  font-weight: 300;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0px;
  text-align: left;
  font-size: 26px;
}

.Modal_description-text-typewriter p {
  padding: 5% 24% 0% 39% !important;
  color: #000;
  font-size: 2vh;
}

.bedroomTDE .Modal_description-text-typewriter p {
  padding: 15% 20% 0 43% !important;
}

/* Specific css for Emotion game TODO refactor */
#container .Modal_description-text-typewriter p {
  padding: 5% 24% 0% 44% !important;
  line-height: 1.5;
  font-size: 1.25rem;
}

.Modal {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 13;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.Modal_blur {
  position: absolute;
  filter: blur(2px);
  z-index: 1;
  width: 100%;
  height: 100%;
  animation: modalActionBlur 0.2s ease;
  animation-fill-mode: forwards;
}

.Modal_description-robot {
  justify-content: center !important;
}

@keyframes modalActionBlur {
  from {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
}

.Typewriter {
  font-size: 1.5vw;
}

.Modal_description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  z-index: 3;
  width: 100%;
  height: 70%;
}

.Modal_description_button {
  display: flex;
  position: absolute;
  top: 15%;
  left: 3%;
}

.Modal_Card_Emotion_Buttons {
  position: relative;
  top: 15% !important;
}

.Modal_description-text {
  font-size: 2rem;
  text-align: center;
  color: #fff;
  margin: 3%;
}

.Modal_description-text_modal p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0px;
  text-align: center;
  font-size: 1.8vw;
}
.Modal_description-card p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0px;
  text-align: left;
  font-size: 1.5vw;
}
.Modal_description-card {
  margin-top: 2%;
}

.Modal_description-text.in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

.Modal_description-text.out {
  opacity: 1;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Modal_description-image {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal_description-button {
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
}

.Modal_description-image img {
  animation: showitem 0.5s ease;
  animation-fill-mode: forwards;
  max-height: 80%;
  max-width: 80%;
}

.Modal_description-content {
  height: 80%;
}

.Modal_description-content-prevention {
  height: 100%;
  display: flex;
  padding: 20px;
  position: relative;
  width: 100%;
  display: contents;
}

.DesktopV2 .Modal_description-content-prevention {
  z-index: -1;
  width: 100%;
  position: absolute;
}

/* Specific css for room messages modal prevention in TMS / RPS, alse we need to refacto TDE / Obesity messages modal prevention because we need one css modal prevention */
.CorridorTMS .Modal_description-content-prevention img,
.RestaurantTMS .Modal_description-content-prevention img,
.ChangingRoomTMS .Modal_description-content-prevention img,
.WelcomeRPS .Modal_description-content-prevention img,
.TabletRPS .Modal_description-content-prevention img,
.BreakRoomRPS .Modal_description-content-prevention img,
/* Emotion game */
#container .Modal_description-content-prevention img {
  z-index: -1;
  height: 70%;
  width: 100%;
  position: absolute;
}

.Modal_description-content-prevention img {
  z-index: -1;
  height: 90%;
  width: 100%;
  position: absolute;
}
.Modal_description-image--reverse img,
.Modal_description-image--reverse {
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .Modal_description-text,
  .Modal p {
    font-size: 1rem;
  }
}
