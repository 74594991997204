.wrapper {
  width: 50%;
  .main-screen {
    right: 2vw;
    position: absolute;
    height: 85%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2901960784);
    border-radius: 10px;
    padding: 5px 30px;
  }

  .footer {
    height: 10vh;
  }
}
