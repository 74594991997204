@font-face {
  font-family: 'EvilEmpireRegular';
  src: url('../../../../../assets/fonts/Evil-Empire-Regular.ttf') format('truetype');
}

.emotion-panel {
  font-family: 'EvilEmpireRegular';
  position: absolute;
  width: 1248px;
  height: 587px;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: grid;
  grid-template-columns: 189px 302px 321px 321px;
  grid-template-rows: 135px repeat(2, 95px) 45px;
  justify-items: center;
  align-items: end;
  row-gap: 1rem;
  column-gap: 1rem;
  padding-left: 1rem;

  // Lights above the emotions intensity
  img[class*='light'] {
    margin-bottom: 1.5rem;
  }

  .light-0 {
    grid-area: 1/2/1/3;
  }

  .light-1 {
    grid-area: 1/3/1/4;
  }

  .light-2 {
    grid-area: 1/4/1/5;
  }

  // Emotions cursor, pointer and buttons
  img[class*='cursor'],
  img[class*='normal-button'] {
    align-self: center;
  }

  .cursor-0,
  .pointer-0,
  .normal-button-0 {
    grid-area: 2/2/4/3;
  }
  .cursor-1,
  .pointer-1,
  .normal-button-1 {
    grid-area: 2/3/4/4;
  }
  .cursor-2,
  .pointer-2,
  .normal-button-2 {
    grid-area: 2/4/4/5;
  }

  button[class*='pointer'] {
    padding: 0;
    border: none;
    background: transparent;
    align-self: center;
    margin-bottom: 2.2rem;
    transform-origin: bottom center;
  }

  p[class*='emotion'] {
    position: absolute;
    margin: 0;
    display: inline;
    font-weight: 600;
  }

  // Weak intensity
  .emotion-0-0 {
    bottom: 20.1rem;
    left: 28rem;
  }

  .selected-button-0-0 {
    bottom: 20.2rem;
    left: 26.5rem;
  }

  .emotion-0-1 {
    bottom: 16.5rem;
    left: 26rem;
  }

  .selected-button-0-1 {
    bottom: 17.7rem;
    left: 25.4rem;
  }

  .emotion-0-2 {
    bottom: 15rem;
    left: 20.5rem;
  }

  .selected-button-0-2 {
    bottom: 16.6rem;
    left: 22.8rem;
  }

  .emotion-0-3 {
    bottom: 17rem;
    left: 15rem;
  }

  .selected-button-0-3 {
    bottom: 17.7rem;
    left: 20.2rem;
  }

  .emotion-0-4 {
    bottom: 20.2rem;
    left: 15rem;
  }

  .selected-button-0-4 {
    bottom: 20.3rem;
    left: 19rem;
  }

  .emotion-0-5 {
    bottom: 23rem;
    left: 15.3rem;
  }

  .selected-button-0-5 {
    bottom: 23rem;
    left: 20.1rem;
  }

  .emotion-0-6 {
    bottom: 25rem;
    left: 20rem;
  }

  .selected-button-0-6 {
    bottom: 24rem;
    left: 22.8rem;
  }

  .emotion-0-7 {
    bottom: 22.9rem;
    left: 26.5rem;
  }

  .selected-button-0-7 {
    bottom: 22.9rem;
    left: 25.4rem;
  }

  // Medium intensity

  .emotion-1-0 {
    bottom: 20.2rem;
    right: 25.3rem;
  }

  .selected-button-1-0 {
    bottom: 20.3rem;
    right: 30.1rem;
  }

  .emotion-1-1 {
    bottom: 17.6rem;
    right: 28rem;
  }

  .selected-button-1-1 {
    bottom: 17.7rem;
    right: 31.2rem;
  }

  .emotion-1-2 {
    bottom: 15rem;
    right: 31.5rem;
  }

  .selected-button-1-2 {
    bottom: 16.7rem;
    right: 33.7rem;
  }

  .emotion-1-3 {
    bottom: 17.6rem;
    left: 35.5rem;
  }

  .selected-button-1-3 {
    bottom: 17.7rem;
    left: 40.7rem;
  }

  .emotion-1-4 {
    bottom: 20.2rem;
    left: 35rem;
  }

  .selected-button-1-4 {
    bottom: 20.3rem;
    left: 39.5rem;
  }

  .emotion-1-5 {
    bottom: 23rem;
    left: 37rem;
  }

  .selected-button-1-5 {
    bottom: 23rem;
    left: 40.5rem;
  }

  .emotion-1-6 {
    bottom: 25rem;
    right: 30.5rem;
  }

  .selected-button-1-6 {
    bottom: 24rem;
    right: 33.7rem;
  }

  .emotion-1-7 {
    bottom: 22.9rem;
    right: 28.5rem;
  }

  .selected-button-1-7 {
    bottom: 23rem;
    right: 31.2rem;
  }

  // High intensity

  .emotion-2-0 {
    bottom: 20.2rem;
    right: 5.8rem;
  }

  .selected-button-2-0 {
    bottom: 20.3rem;
    right: 9rem;
  }

  .emotion-2-1 {
    bottom: 17.5rem;
    right: 4.5rem;
  }

  .selected-button-2-1 {
    bottom: 17.7rem;
    right: 10.2rem;
  }

  .emotion-2-2 {
    bottom: 15rem;
    right: 10rem;
  }

  .selected-button-2-2 {
    bottom: 16.7rem;
    right: 12.7rem;
  }

  .emotion-2-3 {
    bottom: 17.6rem;
    right: 17rem;
  }

  .selected-button-2-3 {
    bottom: 17.7rem;
    right: 15.3rem;
  }

  .emotion-2-4 {
    bottom: 20.2rem;
    right: 18rem;
  }

  .selected-button-2-4 {
    bottom: 20.3rem;
    right: 16.5rem;
  }

  .emotion-2-5 {
    bottom: 23rem;
    right: 17rem;
  }

  .selected-button-2-5 {
    bottom: 22.9rem;
    right: 15.4rem;
  }

  .emotion-2-6 {
    bottom: 25rem;
    right: 10rem;
  }

  .selected-button-2-6 {
    bottom: 24rem;
    right: 12.7rem;
  }

  .emotion-2-7 {
    bottom: 22.9rem;
    right: 3.6rem;
  }

  .selected-button-2-7 {
    bottom: 23rem;
    right: 10.2rem;
  }

  .emoticons-big {
    grid-area: 1/1/3;
    width: 55%;
    align-self: center;
  }
}
