.Steps {
  color: #fff;
  font-size: 1.5rem;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  position: absolute;
  left: 50%;
  height: 10vh;
  transform: translateX(-50%);
  border-radius: 0 0 15px 15px;
  width: 15vw;
}
.Steps img {
  height: 2vw;
  margin: 0px 3px;
}

.Steps-content {
  display: flex;
  align-items: center;
}
.Steps-content p {
  font-size: 1.3vw;
}

.Steps-content-card {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}
