.messages {
  margin: 2%;
  height: 50%;
  .tutorial-background {
    text-align: center;
    display: flex;
    background-size: contain;
    height: 50vh;
    width: 50vw;
    background-repeat: no-repeat;
    padding: 10vh 2vw 10vh 16vw;
  }
}
