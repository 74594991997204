/* MODALS */

.ModalTricky-Background404 {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.ModalTricky-Background404 h3 {
  color: #fff;
  margin-bottom: 0px;
}

.ModalTricky-Container404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  height: 70%;
  width: 70%;
}

.ModalTricky-WrongObject404 {
  width: 100%;
}

.ModalTricky-WrongObject404 img {
  width: 35%;
}

.ModalTricky-p404 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.Hide-Modal {
  display: none !important;
}

/* ANIMATIONS */

.ItemToShow404 {
  height: 60%;
  width: 100%;
}

.ItemToShow404 img {
  animation: showitem404 0.5s linear;
  animation-fill-mode: forwards;
  height: 100%;
}

.zoomleft404 {
  animation: zoomleft404 0.3s linear;
  position: absolute;
  z-index: 4;
}

.zoomleft404 img {
  height: 100%;
}

@keyframes showitem404 {
  from {
    opacity: 0;
    width: 0%;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}

@keyframes zoomleft404 {
  from {
    opacity: 1;
    top: 61%;
    right: 50%;
    height: 9%;
    opacity: 1;
  }
  to {
    position: absolute;
    top: 45%;
    right: 0%;
    opacity: 0.1;
    height: 9%;
    display: none;
  }
}
