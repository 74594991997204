.background {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Fbackgrounds%2Fintro-background.svg?alt=media&token=1b191a05-95f0-44d3-b87c-33930f10a54b');
  background-size: cover;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  padding-top: 10vh;

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    bottom: 0;
    position: absolute;

    .instruction {
      color: white;
      border: 5%;
      background-color: #00000070;
      padding: 1%;
      font-size: x-large;
    }
  }
}
