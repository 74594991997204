.content-textarea {
  color: white;
  background-color: rgba(0, 0, 0, 0.2901960784);
  border-radius: 10px;
  padding: 5px 30px;
  font-size: xx-large;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    height: 250px;
    border-radius: 5%;
  }
  .textarea {
    color: white;
    background-color: rgba(234, 228, 228, 0.2901960784);
    border-radius: 10px;
    margin: 7% 5% 5% 5%;
    width: 100%;
    padding: 2%;
  }
}
