.Weather {
  height: 100%;
}

.Weather .widget {
  border-radius: 0.5rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16), 0 3px 5px rgba(0, 0, 0, 0.23);
  margin: 1rem 0;
  padding: 10%;
}

.Weather .inline-input {
  align-items: flex-start;
  border-bottom: 1px dotted #fff;
  display: flex;
  margin: 0.5rem 0 1.5rem 0;
}

.Weather .inline-input:focus-within {
  border-bottom: 1px dotted #333;
  transition: all 0.3s ease-in-out;
}

.Weather .inline-input .mdi {
  color: #fff;
  padding: 0.2rem 0.5rem 0 0;
}

.Weather .location-input {
  display: flex;
  align-items: flex-start;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  max-width: 16rem;
  outline: none;
  padding-bottom: 0.3rem;
}

.Weather .main-display {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.5rem 0;
}

.Weather .main-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.Weather .temp-measurement {
  color: #333;
  font-size: 5rem;
  font-weight: 500;
  line-height: 0.85;
  width: 6rem;
}

.Weather .sub-info {
  display: flex;
  flex-direction: column;
  margin-left: 0.8rem;
  width: 98px;
}

.Weather .sub-info-title {
  font-size: 1.15rem;
  margin-bottom: 0.3rem;
  height: 1.25rem;
}

.Weather .sub-info-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.25rem;
  font-size: 0.7rem;
  color: #333;
  margin-bottom: 0.4rem;
}

.Weather .sub-info-text .max-temp,
.Weather .sub-info-text .min-temp {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
}

.Weather .sub-info-text .max-temp {
  margin: 0 0.5rem 0 -0.1rem;
}

.Weather .selection-panel {
  margin: 1.8rem 0;
}

.Weather .selection-row {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0.2rem 0 0 0;
}

.Weather .selection-weather {
  color: #333;
  font-size: 0.7rem;
  width: 3.5rem;
}

.Weather .selection-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5%;
}
.Weather .selection-icons svg {
  margin: 0.5rem 0;
}

.Weather .selection-icons p {
  color: #333;
  font-size: 0.7rem;
  font-weight: 700;
}

.Weather .selected p {
  color: #fff;
}

.Weather .cloudy {
  background-image: linear-gradient(0deg, #bbdefb, #2196f3);
  height: 95%;
}

.Weather .clear {
  background-image: linear-gradient(0deg, #b3e5fc, #29b6f6);
}

.Weather .snow {
  background-image: linear-gradient(0deg, #b2ebf2, #26c6da);
  height: 95%;
}

.Weather .rain {
  background-image: linear-gradient(0deg, #c5cae9, #5c6bc0);
  height: 95%;
}

.Weather .thunderstorm {
  background-image: linear-gradient(0deg, #e0e0e0, #757575);
  height: 95%;
}
