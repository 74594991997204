.Bedroom-Cat404 {
  position: absolute;
  z-index: 1;
  width: 13.3%;
  bottom: 45.5%;
  left: 49.6%;
}

#catbed404 img {
  position: absolute;
  z-index: 2;
  width: 28.3%;
  top: 50%;
  left: 10.6%;
}

#catbody404 img {
  position: absolute;
  z-index: 3;
  bottom: 25%;
  animation: breathingcat404 4s ease infinite;
}

#catface404 img {
  position: absolute;
  z-index: 4;
  width: 29.3%;
  bottom: 77%;
  left: 60%;
  animation: catface404 4s linear infinite;
}

#catface404 img:hover {
  animation: catface2404 2s ease;
}

@keyframes breathingcat404 {
  from {
    width: 70%;
    left: 5.3%;
  }
  50% {
    width: 74%;
    left: 3%;
  }
  to {
    width: 70%;
    left: 5.3%;
  }
}

@keyframes catface404 {
  from {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  }
}

@keyframes catface2404 {
  from {
    transform: rotate(5deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  }
}
