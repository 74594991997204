.img-background {
  filter: brightness(0.5);
}

.background,
.loading {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    background-color: white;
    border-radius: 5px;
    padding: 1rem;
  }
}
