.Note {
  display: flex;
  list-style: none;
  flex-direction: column;
  width: 60%;
  margin: 0% 25%;
}

.NoteVac {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 40vh;
  list-style: none;
}

.Note_content_Vac {
  text-decoration: none;
  border-radius: 10px;
  height: 5vw;
  width: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: url('../../../../../../img/pink.cur'), pointer;
  /* margin: 5px; */
}

.Note_content_Vac:hover {
  border: 2px solid white;
  background-color: aliceblue;
  transition-duration: 0.2s;
  transition-property: background-color, color;
}

.Note_content_Vac label:hover {
  color: black;
  transition-duration: 0.2s;
  transition-property: color;
}

.Note_content_Vac label {
  text-align: center;
  font-weight: 800;
  font-size: 1.4vw;
  cursor: url('../../../../../../img/pink.cur'), pointer;
}

.Note_content {
  display: flex;
  text-decoration: none;
  border-radius: 25px;
  margin: 5px;
  align-items: center;
}

.Note_content img {
  height: 25px;
}

.Note_content label {
  margin: 15px;
  cursor: url('../../../../../../img/pink.cur'), pointer;
  font-size: smaller;
}

.Note_content input,
.Note_content_Vac input {
  visibility: hidden;
}

.Note_content--active {
  background-color: aliceblue;
}

.Note_content--active label {
  color: black;
}

.NoteVacc {
  display: flex;
}

.Modal-Container-Question {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 80vh;
  z-index: 3;
  width: 100%;
}

.QuestionnaryContent-Title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 10vh;
}

.QuestionnaryContent-Title h1 {
  font-size: 1.8vw;
  font-weight: 800;
  text-transform: uppercase;
}

.QuestionnaryContent-Title-Question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 15vh;
  font-weight: 800;
}

.QuestionnaryContent-Title-Question p {
  font-weight: 500 !important;
  font-size: 1.4vw;
  line-height: 59px;
}

.QuestionnaryContentVacc-Questions {
  display: flex;
  height: 60vh;
  position: relative;
}

.QuestionnaryContent button p {
  font-size: 1vw;
  font-weight: 700;
}
.QuestionnaryContent_question p {
  justify-content: center !important;
  text-align: center !important;
}

.QuestionnaryContent_questions-btns {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .Note_content img {
    height: 15px;
  }

  .Note_content label {
    margin: 5px;
    cursor: url('../../../../../../img/pink.cur'), pointer;
    font-size: smaller;
  }
}
