.enigma-container {
  display: flex;
  flex-direction: column;
  // center the emotions panel
  align-items: center;
}

.enigma-footer {
  display: flex;
  width: 100%;
  padding-top: 1rem;
  justify-content: space-evenly;

  > div {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    > button {
      display: inherit;
      width: 70px;
      height: 70px;
      padding: 0;
      border: none;
      background: transparent;
    }

    > img {
      margin-top: 0.5rem;
    }
  }
}
