.meeting-footer {
  display: flex;
  position: absolute;
  bottom: 0;

  .meeting-icons {
    margin: 0 10px;
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    img {
      height: 40px;
    }
  }
}
