.Facetime {
  padding: 5px;
}

.Facetime p {
  color: #272929 !important;
}

.Facetime_firstTitle {
  text-align: center;
}

.Facetime_secondTitle {
  margin: 10px 0 !important;
}

.Facetime_secondTitle,
.Facetime_botton-contents-subtitle {
  font-size: smaller;
}

.Facetime_title {
  font-weight: 600 !important;
}
