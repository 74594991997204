.Bureau-img404 {
  position: relative;
  width: 80%;
  z-index: 0;
}

.Bureau-img-div404:focus {
  outline: none;
}

.Text404 {
  position: absolute;
  top: 2%;
  left: 2%;
  width: 25%;
  color: #fff;
  font-size: 1rem;
  text-shadow: 0px 0px 5px #454545;
}

.Img404 img {
  position: absolute;
  top: 5%;
  right: 27%;
  width: 45%;
}

.Cushion404 img {
  position: absolute;
  bottom: 19%;
  left: 2.8%;
  width: 18.3%;
  z-index: 4;
}

/* GAME */

.GameWindow404 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100vh;
  background: linear-gradient(90deg, #552d88, #792887, #cf1c83, #d93a5b, #e76322);
}

/* BUTTONS */

.ButtonAction404 {
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 15px auto;
  font-weight: bold;

  background-size: 200%;
  background-position: left;
  transition: background-position ease-out 0.5s;
  cursor: url('../../../img/pink.cur'), pointer;
}

.Action404 {
  background-image: linear-gradient(
    90deg,
    #552d88,
    #792887,
    #cf1c83,
    #d93a5b,
    #e76322
  );
  background-position: left;
  transition: background-position ease-out 0.5s;
  cursor: url('../../../img/pink.cur'), pointer;
}

.Action404:hover {
  background-position: right;
}

.Cancel404 {
  background-color: #000;
  cursor: url('../../../img/pink.cur'), pointer;
}
.Cancel404:hover {
  background-color: darkslategray;
  cursor: url('../../../img/pink.cur'), pointer;
}

.Buttons {
  display: flex;
  justify-content: center;
}

.Buttons button {
  margin: 10px;
}

@media screen and (max-width: 1200px) {
  .Text404 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .Text404 {
    font-size: 0.8rem;
  }
}

.cursor-clicked404 {
  z-index: 10;
  position: absolute;
  transform: translate(-50%, -40%);
  pointer-events: none;
  backface-visibility: hidden;
}

.cursor-clicked404 p {
  color: transparent;
  visibility: visible;
  pointer-events: none;
  backface-visibility: hidden;
  color: #fff;
  animation: bubbletext404 1s ease;
  animation-fill-mode: forwards;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

@keyframes bubbletext404 {
  from {
    font-size: 0rem;
  }
  30% {
    font-size: 2rem;
    opacity: 1;
  }
  to {
    font-size: 2rem;
    opacity: 0;
  }
}

.bubble404 {
  position: absolute;
  z-index: 1000;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: 2px solid #fff;
  animation: bubbleplop404 1s ease;
  animation-fill-mode: forwards;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}

@keyframes bubbleplop404 {
  from {
    width: 0%;
    height: 0%;
  }
  30% {
    width: 5%;
    height: 8%;
    opacity: 1;
  }
  to {
    width: 5.5%;
    height: 11%;
    opacity: 0;
  }
}
